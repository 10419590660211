import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Directive, inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { distinctUntilChanged, map, Subscription, tap } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[onMobile]',
  standalone: true,
})
export class OnMobileDirective implements OnInit, OnDestroy {
  @Input('onMobile') show = true;
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly templateRef = inject(TemplateRef<any>);
  private readonly viewContainer = inject(ViewContainerRef);
  private sub!: Subscription;


  ngOnInit() {
    this.sub = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map((result: BreakpointState) => result.matches),
      distinctUntilChanged(),
      tap(t => (t === this.show) ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear())
    ).subscribe();
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
